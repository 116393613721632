import React from "react";

const X = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      className="home__rrss-icon"
    >
      <title>Twitter / X Bizarrap</title>
      <circle cx="18" cy="18" r="18" fill="black" />
      <path
        d="M23.8118 9H26.6957L20.3952 16.201L27.8072 26H22.0037L17.4582 20.057L12.257 26H9.37138L16.1104 18.2977L9 9H14.9509L19.0596 14.4322L23.8118 9ZM22.7996 24.2738H24.3976L14.0826 10.6355H12.3677L22.7996 24.2738Z"
        fill="white"
      />
    </svg>
  );
};

export default X;
