import React from "react";

const YouTube = () => {
  return (
    <svg
      className="home__rrss-icon"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Youtube Bizarrap</title>
      <circle cx="18" cy="18" r="18" fill="black" />
      <path
        d="M18.244 10C18.778 10.003 20.114 10.016 21.534 10.073L22.038 10.095C23.467 10.162 24.895 10.278 25.604 10.475C26.549 10.741 27.291 11.515 27.542 12.497C27.942 14.057 27.992 17.099 27.998 17.836L27.999 17.988V18.162C27.992 18.899 27.942 21.942 27.542 23.501C27.288 24.486 26.545 25.261 25.604 25.523C24.895 25.72 23.467 25.836 22.038 25.903L21.534 25.926C20.114 25.982 18.778 25.996 18.244 25.998L18.009 25.999H17.754C16.624 25.992 11.898 25.941 10.394 25.523C9.45 25.257 8.707 24.483 8.456 23.501C8.056 21.941 8.006 18.899 8 18.162V17.836C8.006 17.099 8.056 14.056 8.456 12.497C8.71 11.512 9.453 10.737 10.395 10.476C11.898 10.057 16.625 10.006 17.755 10H18.244ZM15.999 14.5V21.5L21.999 18L15.999 14.5Z"
        fill="white"
      />
    </svg>
  );
};

export default YouTube;
