import React from "react";

const ClosedIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5103 37.336C39.6615 37.4768 39.7827 37.6467 39.8668 37.8354C39.9509 38.0241 39.9961 38.2278 39.9998 38.4344C40.0034 38.641 39.9654 38.8462 39.888 39.0377C39.8107 39.2293 39.6955 39.4033 39.5494 39.5494C39.4033 39.6955 39.2293 39.8107 39.0377 39.888C38.8462 39.9654 38.641 40.0034 38.4344 39.9998C38.2278 39.9961 38.0241 39.9509 37.8354 39.8668C37.6467 39.7827 37.4768 39.6615 37.336 39.5103L19.9616 22.1385L2.58727 39.5103C2.29562 39.7821 1.90989 39.93 1.51132 39.923C1.11276 39.916 0.732478 39.7545 0.450605 39.4726C0.168732 39.1908 0.00727193 38.8105 0.000239687 38.4119C-0.00679255 38.0134 0.141152 37.6276 0.412906 37.336L17.7847 19.9616L0.412906 2.58727C0.141152 2.29562 -0.00679255 1.90989 0.000239687 1.51132C0.00727193 1.11276 0.168732 0.732478 0.450605 0.450605C0.732478 0.168732 1.11276 0.00727193 1.51132 0.000239687C1.90989 -0.00679255 2.29562 0.141152 2.58727 0.412906L19.9616 17.7847L37.336 0.412906C37.6276 0.141152 38.0134 -0.00679255 38.4119 0.000239687C38.8105 0.00727193 39.1908 0.168732 39.4726 0.450605C39.7545 0.732478 39.916 1.11276 39.923 1.51132C39.93 1.90989 39.7821 2.29562 39.5103 2.58727L22.1385 19.9616L39.5103 37.336Z"
        fill="white"
      />
    </svg>
  );
};

export default ClosedIcon;
