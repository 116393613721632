import React from "react";
import "./card.scss";
import papper from "../../../../../assets/LiveTour/papper.webp";
import papper2 from "../../../../../assets/LiveTour/papper-2.webp";
import papper3 from "../../../../../assets/LiveTour/papper-1.webp";
import { useState } from "react";

const Card = ({ date, image, even }) => {
  const [imagePolaroidLoad, setImagePolaroidLoad] = useState(null);
  const [imagePapperLoad, setImagePapperLoad] = useState(null);

  function formatDate(date) {
    let day = date.getUTCDate();
    let month = date.toLocaleDateString("es-ES", { month: "2-digit" });
    return `${day}.${month}`;
  }

  return (
    <div
      className={
        even
          ? `card-polaroid card-polaroid--even ${
              imagePolaroidLoad && "card-polaroid--show"
            }`
          : `card-polaroid ${imagePolaroidLoad && "card-polaroid--show"}`
      }
    >
      <img
        className="card-polaroid__background"
        src={image}
        alt="polaroid bizarrap"
        loading="lazy"
        onLoad={() => setImagePolaroidLoad(true)}
      />
      <a href={date?.link} target="_blank" className="card-polaroid__btn">
        <div className="card-polaroid__btn--dt">
          <img src={papper3} alt="papper" className="card-polaroid__btn--bg" />
          {imagePolaroidLoad && (
            <p className="card-polaroid__btn--text">Comprar</p>
          )}
        </div>
      </a>
      <div className="card-polaroid__papper-wrapper">
        <div className="card-polaroid__location-wrapper">
          <img
            className="card-polaroid__papper card-polaroid__papper--1"
            src={papper}
            alt="papper"
            onLoad={() => setImagePapperLoad(true)}
          />
          <img
            className="card-polaroid__papper card-polaroid__papper--2"
            src={papper2}
            alt="papper"
            onLoad={() => setImagePapperLoad(true)}
          />
          <div className="card-polaroid__location-container">
            {imagePolaroidLoad && imagePapperLoad && (
              <>
                <span>
                  {date?.city && `${date?.city} - `}
                  {date?.country}
                </span>

                <span>{date?.show}</span>
              </>
            )}
          </div>
        </div>
      </div>
      {imagePolaroidLoad && (
        <div className="card-polaroid__date-container">
          <span>{formatDate(new Date(date?.date))}</span>
        </div>
      )}
    </div>
  );
};

export default Card;
