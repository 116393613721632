import React from "react";

const Peru = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path fill="#D91023" d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5H4z" />
      <path fill="#EEE" d="M12 5h12v26H12z" />
      <path fill="#D91023" d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4z" />
    </svg>
  );
};

export default Peru;
